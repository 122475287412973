<template>
    <div class="flex flex-col md:flex-row w-full ">
        <div class="w-full p-4 self-start mt-2 z-0">
            <p class=" text-gray-900 font-bold text-4xl my-4" >Modifica REFILL</p>
            <form>
                <!-- manuale -->
                <div  class="mb-4 flex-col items-center">
                    <div class="flex items-center">
                        <label class="block text-gray-700 font-bold " :for="'latitude'">
                        Latitudine
                        </label>
                        <input v-model="newPoint.lat" @input="changePointMap()" id="address"
                            class="appearance-none border rounded w-2/6 py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Latitudine" required />
                        <label class="block text-gray-700 font-bold " for="longitudine">
                        Longitudine
                        </label>
                        <input v-model="newPoint.lng" @input="changePointMap()" id="address" 
                            class="appearance-none border rounded w-2/6 py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Longitudine" required />
                    </div>
                    <div class="flex items-center">
                        <label class="block text-gray-700 font-bold " :for="'latitude'">
                        Indirizzo
                        </label>
                        <input v-if="!changeAddress" v-model="newPoint.address" :id=" 'address'"
                            class="appearance-none border rounded w-full py-2 px-3 m-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            :placeholder="'Inserisci l\'indirizzo'" required />
                        <GMapAutocomplete v-if="changeAddress"
                            class="appearance-none border rounded w-full py-2 px-3 m-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Inserisci l'indirizzo" @place_changed="completaIndirizzo">
                        </GMapAutocomplete>
                        <button v-if="!changeAddress" type="button" @click="changeAddress=true"
                            class="px-4 py-2 rounded bg-blue-500 text-white font-semibold focus:outline-none focus:shadow-outline mr-3">
                            Cambia
                        </button>
                        <button v-if="changeAddress" type="button" @click="changeAddress=false"
                            class="px-4 py-2 rounded bg-red-500 text-white font-semibold focus:outline-none focus:shadow-outline mr-3">
                            Annulla
                        </button>
                    </div>
                </div>
                <div class="mb-4 flex items-center">
                    <label class="block text-gray-700 font-bold" for="name">
                        Nome
                    </label>
                    <input v-model="newPoint.name" id="name"
                        class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Inserisci il nome del luogo" required />
                </div>
                <div class="flex justify-end">
                    <button type="button"
                        class="mr-2 px-4 py-2 rounded bg-red-500 text-white font-semibold focus:outline-none focus:shadow-outline"
                        @click="$emit('closePopUp')">
                        Annulla
                    </button>
                    <button type="button" @click="applyModifyPoint()"
                        class="px-4 py-2 rounded bg-blue-500 text-white font-semibold focus:outline-none focus:shadow-outline">
                        Aggiorna
                    </button>
                </div>
            </form>
        </div>
        <div class="w-full md:w-3/6 md:h-auto bg-gray-200"> <mapEngine ref="mapEngine" /> </div>
    </div>
</template>
  
<script>
import mapEngine from '../mapEngine.vue'

export default {
    name:"modifyRefillPoint",
    components:{
        mapEngine
    },
    data() {
        return {
            insertMode: "address",
            researchValueIsChanged:true,
            newPoint: {
                name: "",
                address: "",
                lat:"",
                lng:"",
                id:""
            },
            changeAddress:false,
        };
    },
    mounted(){
    },
    methods: {
        setPoint(newPoint){
            this.newPoint = newPoint
            this.changePointMap()
        },
        changePointMap(){
            if(this.newPoint.lat === '' || this.newPoint.lng === '' ){return}
            if(this.newPoint.lat === undefined || this.newPoint.lng === undefined ){return}
            this.$refs.mapEngine.addMarker(this.newPoint.lat,this.newPoint.lng)
        },
        coordToAddress() { //da coordinate a indirizzo
            if (this.newPoint.lat === '' || this.newPoint.lng === '') {
                window.alert("Inserisci entrambe le coordinate");
                return;
            }
            const apiKey = 'AIzaSyBgLYnMiKIlH5RjxmfKD-NhcIgUX-VjCsw'; // Sostituisci con la tua chiave API di Google Maps
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.newPoint.lat},${this.newPoint.lng}&key=${apiKey}`;

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.results.length > 0) {
                        const addressData = data.results[0];
                        console.log(addressData);
                        if(addressData.name){this.newPoint.name = addressData.name}
                        this.newPoint.address = `${(addressData.name || '')}, ${addressData.formatted_address}`
                        this.newPoint.lat = addressData.geometry.location.lat;
                        this.newPoint.lng = addressData.geometry.location.lng;
                        this.changePointMap();
                    } else {
                        window.alert("Nessun indirizzo trovato");
                    }
                })
                .catch(error => {
                    console.error(error);
                    window.alert("Errore nella ricerca, controlla le coordinate");
                });
        },
        addressToCoord() {  //da indirizzo a coordinate
            fetch(`https://nominatim.openstreetmap.org/search?q=${this.newPoint.address}&format=json&accept-language=en`)
                .then(response => response.json())
                .then(data => {
                    console.log(data)
                    if (data.length === 0) {
                        window.alert("Nessun indirizzo trovato")
                    } else {
                        const lat = data[0].lat;
                        const lng = data[0].lon;
                        this.newPoint.lat = lat;
                        this.newPoint.lng = lng;
                    }
                })
                .catch(error => { console.error(error); window.alert("Errore nella ricerca, controlla le coordinate"); });
        },
        cercaIndirizzo() {
            if (this.newPoint.address == '') { window.alert("inserisci un'indirizzo"); return }
            const apiKey = 'AIzaSyBgLYnMiKIlH5RjxmfKD-NhcIgUX-VjCsw'; // Sostituisci con la tua chiave API di Google Maps
            const encodedAddress = encodeURIComponent(this.newPoint.address);
            const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.results.length > 0) {
                        const location = data.results[0].geometry.location;
                        this.newPoint.lat = location.lat;
                        this.newPoint.lng = location.lng;
                        this.coordToAddress();
                    } else {
                        window.alert("Nessun indirizzo trovato");
                    }
                })
                .catch(error => {
                    console.error(error);
                    window.alert("Errore nella ricerca, controlla l'indirizzo");
                });
        },
        completaIndirizzo(place) {
            console.log(place)
            this.researchValueIsChanged = false;
            this.newPoint.name = place.name || '';
            this.newPoint.address = `${(place.name || '')}, ${place.formatted_address}`
            this.newPoint.lat = place.geometry.location.lat();
            this.newPoint.lng = place.geometry.location.lng();
            this.changePointMap();
        },
        applyModifyPoint(){
            let t = this
            if(this.newPoint.lat === '' || this.newPoint.lng === '' || this.newPoint.address === '' || this.newPoint.name === '' || this.newPoint.name === null || this.newPoint.id === "" ){window.alert("inserisci tutti i campi"); return}
            const bodyReq = this.newPoint
            bodyReq['sessionId'] = localStorage.getItem("sessionId")
            let urlDir;
            if(this.$root.adminInfo.adminlevel <= 1){
                urlDir = 'modifyRefillPoint/ById'
            }else if(this.$root.adminInfo.adminlevel == 2 ){
                urlDir = 'modifyRefillPoint/ById/onlyCreatedByYou'
            }
            this.$axios.post(`${this.$root.apiBaseUrl}/${urlDir}`, bodyReq)
            .then(function () {
                t.$emit("updatedPoint")
            })
            .catch(function (error) {
            console.log(error);
            });
        }
    }
};
</script>
  
<style>
input:focus {
    outline: none;
    box-shadow: none;
}
</style>