<template>
    <div class="flex flex-col md:flex-row w-full ">
        <div class="w-full p-4 self-start mt-2 z-0">
            <p class=" text-gray-900 font-bold text-4xl mb-6" >Aggiungi una news</p>
            <div>
                <div class="flex">
                    <!-- manuale -->
                    <div class="mb-4 flex-col items-center w-11/12">
                        <div class=" items-center">
                            <label class="block text-gray-700 font-bold mt-2" :for="'latitude'">
                            Titolo
                            </label>
                            <input v-model="newNews.title" id="address"
                                class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Titolo" required />
                            <label class="block text-gray-700 font-bold mt-2" for="longitudine">
                            Descrizione
                            </label>
                            <textarea v-model="newNews.description"  id="descrizione" 
                                class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Descrizione" required></textarea>
                            <label class="block text-gray-700 font-bold mt-2" for="longitudine">
                            Link articolo
                            </label>
                            <input v-model="newNews.url"  id="link" 
                                class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Link articolo" required />
                        </div>
                    </div>
                    <div class=" h-48 w-40 mx-8 my-2">
                        <p class=" text-left w-full px-4 py-2  text-gray-700 focus:outline-none focus:shadow-outline bg-gray-300" >Paesi </p>
                        <div class=" border rounded overflow-auto h-full w-full">
                            <button class=" text-left w-full px-4 py-2 my-px rounded text-gray-700 focus:outline-none focus:shadow-outline" :class="newNews.selectedCountry.length === avabileCountry.length?'bg-blue-500 text-white':'bg-gray-200'" @click="selectOrDeselectCountry('All')" >{{newNews.selectedCountry.length === avabileCountry.length?'Deselect all':'Select all'}} </button>
                            <div v-for="country in avabileCountry" :key="country"> 
                                <button class="text-left w-full px-4 py-2 my-px rounded text-gray-700 focus:outline-none focus:shadow-outline" :class="newNews.selectedCountry.indexOf(country.country) != -1?'bg-blue-500 text-white':'bg-gray-200'" @click="selectOrDeselectCountry(country.country)"> {{ country.country }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="imageSrc != null" class=" items-center w-full mt-2">
                    <label class="block text-gray-700 font-bold my-2 " for="longitudine">
                            Immagine:
                    </label>
                    <div class="relative h-52 w-52">
                        <button @click="deleteImage()" type="button" class="absolute top-0 right-0 bg-white h-6 w-6 rounded-full mt-1 mr-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" data-v-2b2c4366=""><svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" data-v-2b2c4366=""><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" data-v-2b2c4366=""></path></svg></button>
                        <img :src="imageSrc" alt="Image" class=" rounded-lg object-cover h-52 w-52 object-centre object-contain">
                    </div>
                </div>
                <div class="mb-4 flex items-center " v-if="imageSrc == null">
                    <div class="h-32 w-full border-4 border-dashed rounded-lg border-gray-400 flex justify-center items-center" @dragover.prevent @drop="onDrop">
                        <label class="block my-2 text-ml font-bold text-gray-700 " for="file_input">Upload file:</label>
                        <input @change="onSelectImage" @dragover.prevent @drop="onSelectImage" accept=".png,.jpg,.jpeg" class=" m-2 block text-sm text-gray-900 cursor-pointer bg-gray-50 focus:outline-none" id="file_input" type="file">
                    </div>
                </div>
                <div class="flex justify-end">
                    <button type="button"
                        class="mr-2 px-4 py-2 rounded bg-red-500 text-white font-semibold focus:outline-none focus:shadow-outline"
                        @click="$emit('closePopUp')">
                        Annulla
                    </button>
                    <button v-if="!uploadNewsLoading" type="button" @click="addNewNews()"
                        class="px-4 py-2 rounded bg-blue-500 text-white font-semibold focus:outline-none focus:shadow-outline">
                        Aggiungi news
                    </button>
                    <div v-if="uploadNewsLoading" class=" py-2 rounded bg-blue-700 px-16 text-white font-semibold focus:outline-none focus:shadow-outline">
                        <svg  role="status" class=" text-gray-200 animate-spin fill-blue-400 h-6" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-2b2c4366=""><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" data-v-2b2c4366=""></path><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" data-v-2b2c4366=""></path></svg>
                    </div>
                </div>
            </div>
        </div>   
        </div>
</template>
  
<script>
export default {
    name:"addNews",
    components:{
        
    },
    data() {
        return {
            uploadNewsLoading:false,
            researchValueIsChanged:true,
            newNews: {
                title: "",
                description: "",
                image:"",
                url:"",
                selectedCountry:[],
            },
            imageSrc:null,
            avabileCountry:[],
            allCountries:[],
            countryGetStep:0,   //quando è 2 allora sono stati presi sia tutti gli stati disponibili che quelli presento nel db, così posso unirli
        };
    },
    mounted(){
        this.getAvableCountry()
        this.getAllCountry()
    },
    methods: {
        addNewNews(){
            let t = this
            if(this.newNews.title === '' || this.newNews.description === '' || this.newNews.image === '' || this.newNews.url === '' || this.newNews.selectedCountry === []){window.alert("inserisci tutti i campi"); return}
            
            const formData = new FormData(); // creao il form per l'immagine
            formData.append('title', this.newNews.title);
            formData.append('description', this.newNews.description);
            formData.append('url', this.newNews.url);
            formData.append('selectedCountry', this.newNews.selectedCountry);
            formData.append('image', this.newNews.image)

            this.uploadNewsLoading = true
            this.$axios.post(`${this.$root.apiBaseUrl}/insertNews`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    sessionId: localStorage.getItem("sessionId")
                }
            })
            .then(function (response) {
                console.log(response);
                t.uploadNewsLoading = false
                t.$emit("insertNews")
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getAvableCountry(){
            let t = this
            this.$axios.get(`${this.$root.apiBaseUrl}/getNewsAvabileCountry`)
            .then(function (response) {
                t.avabileCountry = response.data
                t.countryGetStep++
                if(t.countryGetStep==2){
                        t.mergeArrayCountry()
                    }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        selectOrDeselectCountry(country){
            if(country === 'All'){
                if(this.newNews.selectedCountry.length != this.avabileCountry.length){  // TODO: FIX NON VA
                    this.newNews.selectedCountry = Object.values(this.avabileCountry).map(countryObj => countryObj.country);
                }else{
                    this.newNews.selectedCountry = []
                }
            }else{
                if(this.newNews.selectedCountry.indexOf(country) == -1){
                    this.newNews.selectedCountry.push(country)
                }else{
                    this.newNews.selectedCountry.splice(this.newNews.selectedCountry.indexOf(country), 1)
                }
            }
        },
        onDrop(event) {
            event.preventDefault();

            if(!(event.dataTransfer.files[0].type === "image/jpeg" ||
             event.dataTransfer.files[0].type ===  "image/jpg" ||
              event.dataTransfer.files[0].type ===  "image/png")){window.alert('File non supportato'); return}//se non é un immagine non la carico
            const file = event.dataTransfer.files[0];
            this.showImage(file);
            this.newNews.image =  file
        },
        onSelectImage(event) {
            const file = event.target.files[0];
            this.showImage(file);
            this.newNews.image =  file
        },
        showImage(file) {
            const reader = new FileReader();
            reader.addEventListener("load",() => {
                    this.imageSrc = reader.result;
                },
            );
            reader.readAsDataURL(file);
        },
        deleteImage(){
            this.imageSrc=null;
            this.newNews.image="";
        },
        getAllCountry(){
            let t = this
            this.$axios.get(`https://restcountries.com/v3.1/all`)
            .then(function (response) {
                for (let index = 0; index < response.data.length; index++) {
                    t.allCountries.push(response.data[index].name.common)
                }
                t.countryGetStep++
                    if(t.countryGetStep==2){
                        t.mergeArrayCountry()
                    }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        mergeArrayCountry(){
            for (let index = 0; index < this.avabileCountry.length; index++) {
                this.allCountries.splice(this.allCountries.indexOf(this.avabileCountry[index].country), 1);
            }
            this.allCountries.sort()
            for (let index = 0; index < this.allCountries.length; index++) {
                this.avabileCountry.push({country: this.allCountries[index]});
            }
        }
    }
};
</script>
  
<style>
input:focus {
    outline: none;
    box-shadow: none;
}
</style>