<template>
    <div class="flex flex-col md:flex-row w-full ">
        <div class="w-full p-4 self-start mt-2 z-0">
            <p class=" text-gray-900 font-bold text-4xl my-4" >Modifica Admin</p>
            <div>
                <!-- manuale -->
                <div class="mb-4 flex items-center">
                    <label class="block text-gray-700 font-bold" for="name">
                        Username
                    </label>
                    <input v-model="admin.username" id="name"
                        class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Inserisci username" required />
                </div>
                <div class="mb-4 flex items-center">
                    <label class="block text-gray-700 font-bold" for="name">
                        Email
                    </label>
                    <input v-model="admin.email" id="name"
                        class="appearance-none border rounded w-full py-2 px-3 mx-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Inserisci email" required />
                </div>
                <div class="mb-4 flex items-center">
                    <label class="block text-gray-700 font-bold" for="name">
                        Tipo
                    </label>
                    <div  class="flex px-2"> 
                        <button class="px-2 mx-2 text-sm text-gray-500 rounded-md " :class="admin.adminlevel==0?'bg-purple-200':'bg-gray-200'" @click="admin.adminlevel=0">admin</button>
                        <button class="px-2 mx-2 text-sm text-gray-500 rounded-md " :class="admin.adminlevel==1?'bg-green-200':'bg-gray-200'" @click="admin.adminlevel=1" >editor</button>
                        <button class="px-2 mx-2 text-sm text-gray-500 rounded-md " :class="admin.adminlevel==2?'bg-orange-200':'bg-gray-200'" @click="admin.adminlevel=2" >distributor</button>
                    </div>
                </div>
                <div class="flex justify-end">
                    <button type="button"
                        class="mr-2 px-4 py-2 rounded bg-red-500 text-white font-semibold focus:outline-none focus:shadow-outline"
                        @click="$emit('closePopUp')">
                        Annulla
                    </button>
                    <button type="button" @click="applyModifyAdmin()"
                        class="px-4 py-2 rounded bg-blue-500 text-white font-semibold focus:outline-none focus:shadow-outline">
                        Aggiorna
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name:"modifyAdmin",
    components:{
        
    },
    data() {
        return {
            admin: {
                username: "",
                email:"",
                adminlevel:0,
            },
        };
    },
    mounted(){
    },
    methods: {
        applyModifyAdmin(){
            let t = this
            if(this.admin.username === '' || this.admin.email === '' || this.admin.adminlevel === ''  ){window.alert("inserisci tutti i campi"); return}
            this.$axios.post(`${this.$root.apiBaseUrl}/addAdmin`, {
                sessionId: localStorage.getItem("sessionId"),
                adminUsername: this.admin.username,
                adminLevel: this.admin.adminlevel,
                adminEmail: this.admin.email,

            })
            .then(function () {
                t.$emit("insertAdmin")
            })
            .catch(function (error) {
                console.log(error);
                if(error.response.status === 409){
                    window.alert("Email già usata");
                }
            });
        },
    }
};
</script>
  
<style>
input:focus {
    outline: none;
    box-shadow: none;
}
</style>