<template>
    <addAdmin v-if="addAdmin" @closePopUp="addAdmin = false" @insertAdmin="updateData(), addAdmin = false" class="border border-gray-300 drop-shadow-2xl my-20 ml-60 fixed inset-0 flex items-center justify-center bg-gray-50 z-50 h-fit px-10 py-10 rounded-lg " style="width:77%"/>
    <modifyAdmin v-if="modifyAdmin" ref="modifyAdminRef" @closePopUp="modifyAdmin = false" @updateAdmin="updateData(), modifyAdmin = false" class="border border-gray-300 drop-shadow-2xl my-20 ml-60 fixed inset-0 flex items-center justify-center bg-gray-50 z-50 h-fit px-10 py-10 rounded-lg " style="width:77%"/>

    <div v-if="deleteAdminConfirmation" class="flex flex-col justify-center items-center fixed z-40 w-5/6 h-5/6 ">
        <div class="flex flex-col justify-center drop-shadow-2xl items-center bg-gray-50 p-20 items-center justify-center rounded-lg border border-gray-300">
            <h1 class="text-4xl font-bold mb-4">Eliminare l'admin?</h1>
            <div class="flex">
                <button class="px-4 py-2 bg-red-500 text-white font-bold rounded m-2" @click="deleteAdmin(adminToDelete)">Conferma</button> 
                <button class="px-4 py-2 bg-blue-500 text-white font-bold rounded m-2" @click="adminToDelete=undefined, deleteAdminConfirmation=false">Annulla</button>
            </div>
        </div>
    </div>

    <div class="">
        <div class="w-full p-4">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full fixed top-4 right-4" @click="addAdmin=true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M11 9V5a1 1 0 0 0-2 0v4H5a1 1 0 0 0 0 2h4v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2h-4z" clip-rule="evenodd" />
                </svg>
            </button>
            <table class="w-full border-collapse">
                <thead>
                    <tr class="bg-gray-200">
                        <th class="px-4 py-2 text-left">Username</th>
                        <th class="px-4 py-2 text-left">E-mail</th>
                        <th class="px-4 py-2 text-left">Admin level</th>
                        <th class=" text-center w-8"></th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr v-for="(row) in refillPoints" :key="row" >
                        <td class="border px-4 py-2">{{ row.username }}</td>
                        <td class="border px-4 py-2">{{ row.email }}</td>
                        <td class="border px-4 py-2">
                            <span v-if="row.adminlevel==0" class="px-2 text-sm text-gray-500 rounded-md bg-purple-200 "> admin </span>
                            <span v-if="row.adminlevel==1" class="px-2  text-sm text-gray-500 rounded-md bg-green-200" >editor</span>
                            <span v-if="row.adminlevel==2" class="px-2  text-sm text-gray-500 rounded-md bg-orange-200 "  >distributor</span>
                        </td>
                        <td class="border "> 
                            <div class="flex">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded-md m-px" @click="modifyAdminFunction({id:row.id ,username: row.username, email: row.email ,adminlevel:row.adminlevel})">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="fill-current w-4 h-4  justify-self-center"  viewBox="0 96 960 960" ><path d="M180 876h44l443-443-44-44-443 443v44Zm614-486L666 262l42-42q17-17 42-17t42 17l44 44q17 17 17 42t-17 42l-42 42Zm-42 42L248 936H120V808l504-504 128 128Zm-107-21-22-22 44 44-22-22Z"/></svg>
                                </button>
                                <button  class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded-md m-px" @click="deleteAdmin(row.id)">
                                    <svg class="fill-current w-4 h-4  justify-self-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M15 5a2 2 0 00-2-2H7a2 2 0 00-2 2H2v2h1l1.11 12.647A2 2 0 005.08 19h9.84a2 2 0 001.97-1.353L17 7h1V5h-3zm-6-1h4a1 1 0 011 1H8a1 1 0 011-1zm6 14H5.08l-.88-10h12.8l-.88 10zM7 9v8h1V9H7zm3 0v8h1V9h-1zm3 0v8h1V9h-1z"/>
                                    </svg>
                                </button>
                            </div>    
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="dataLoading" class="w-full my-2 items-center flex justify-center align-center">
                <svg role="status" class="justify-self-center text-gray-200 animate-spin fill-blue-400 h-10 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-2b2c4366=""><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" data-v-2b2c4366=""></path><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" data-v-2b2c4366=""></path></svg>
            </div>
        </div>
    </div>
</template>
  
<script>
import addAdmin from './addAdmin.vue'
import modifyAdmin from './modifyAdmin.vue'


export default {
    name: 'manageAdmin',
    components: {
        addAdmin,
        modifyAdmin,
    },
    data() {
        return {
            refillPoints: [],
            researchLuogo: '',
            addAdmin:false,
            modifyAdmin:false,
            deleteAdminConfirmation:false,
            dataLoading:false,
        };
    },
    mounted() {
        this.updateData()
    },
    methods: {
        updateData() {
            this.dataLoading=true
            this.$axios
                .get(`${this.$root.apiBaseUrl}/getAllAdmin`,  {
                    headers:{sessionId:localStorage.getItem("sessionId")}
                })
                .then((response) => {
                    this.dataLoading=false
                    this.refillPoints = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        deleteAdmin(id){
            this.adminToDelete = id  // assegno per il popup di conferma che richiama questa funzione
            if(!this.deleteAdminConfirmation){
                this.deleteAdminConfirmation = true
            }else{            
                this.$axios
                .post(`${this.$root.apiBaseUrl}/removeAdmin`, {id:id,sessionId:localStorage.getItem("sessionId")})
                .then(() => {
                    this.updateData()
                })
                .catch((error) => {
                    console.log(error);

                })
                this.deleteAdminConfirmation = false
                this.adminToDelete = undefined
            }
        },
        modifyAdminFunction(adminToModify){
            this.modifyAdmin = true
            setTimeout(()=> { this.$refs.modifyAdminRef.setAdmin(adminToModify)}, 50)
        }
    }
};
</script>

<style></style>