<template>
    <div v-if="deleteUserConfirmation" class="flex flex-col justify-center items-center fixed z-40 w-5/6 h-5/6 ">
        <div class="flex flex-col justify-center drop-shadow-2xl items-center bg-gray-50 p-20 items-center justify-center rounded-lg border border-gray-300">
            <h1 class="text-4xl font-bold mb-4">Eliminare l'utente?</h1>
            <div class="flex">
                <button class="px-4 py-2 bg-red-500 text-white font-bold rounded m-2" @click="deleteUser(adminToDelete)">Conferma</button> 
                <button class="px-4 py-2 bg-blue-500 text-white font-bold rounded m-2" @click="adminToDelete=undefined, deleteUserConfirmation=false">Annulla</button>
            </div>
        </div>
    </div>

    <div class="">
        <div class="w-full p-4">
            <div v-if="csvLoading" class="rounded-full fixed top-4 right-4  mx-15 px-7 bg-blue-700 inline w-24 h-10 mr-2">
                <svg  role="status" class=" text-gray-200 animate-spin fill-blue-400 m-1" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-2b2c4366=""><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" data-v-2b2c4366=""></path><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" data-v-2b2c4366=""></path></svg>
            </div>
            <button v-if="!csvLoading" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full fixed top-4 right-4" @click="esportUsersCSV()">
                Export CSV
            </button>
            <table class="w-full border-collapse">
                <thead>
                    <tr class="bg-gray-200">
                        <th class="px-4 py-2 text-left">Username</th>
                        <th class="px-4 py-2 text-left">E-mail</th>
                        <th class="px-4 py-2 text-left">Country</th>
                        <th class="px-4 py-2 text-left">ADV emails</th>
                        <th v-if="$root.adminInfo.adminlevel==0" class="px-4 py-2 text-left">Signup with</th>
                        <th class=" text-center w-8"></th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr v-for="(row) in users" :key="row" >
                        <td class="border px-4 py-2">{{ row.username }}</td>
                        <td class="border px-4 py-2">{{ row.email }}</td>
                        <td class="border px-4 py-2">{{ row.country }}</td>
                        <td class="border px-4 py-2 text-center">{{ row.allow_advertising_email?'✅':'❌' }}</td>
                        <td class="border px-4 py-2">{{ row.signup_with }}</td>
                        <td v-if="$root.adminInfo.adminlevel==0" class="border "> 
                            <div class="flex">
                                <button  class="bg-red-500 hover:bg-red-700 text-white font-bold p-2 rounded-md m-px" @click="deleteUser(row.id)">
                                    <svg class="fill-current w-4 h-4  justify-self-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path d="M15 5a2 2 0 00-2-2H7a2 2 0 00-2 2H2v2h1l1.11 12.647A2 2 0 005.08 19h9.84a2 2 0 001.97-1.353L17 7h1V5h-3zm-6-1h4a1 1 0 011 1H8a1 1 0 011-1zm6 14H5.08l-.88-10h12.8l-.88 10zM7 9v8h1V9H7zm3 0v8h1V9h-1zm3 0v8h1V9h-1z"/>
                                    </svg>
                                </button>
                            </div>    
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="dataLoading" class="w-full my-2 items-center flex justify-center align-center">
                <svg role="status" class="justify-self-center text-gray-200 animate-spin fill-blue-400 h-10 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-2b2c4366=""><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" data-v-2b2c4366=""></path><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" data-v-2b2c4366=""></path></svg>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'manageAdmin',
    data() {
        return {
            users:[],
            deleteUserConfirmation:false,
            csvLoading:false,
            dataLoading:false
        };
    },
    mounted() {
        this.updateData()
    },
    methods: {
        updateData() {
            this.dataLoading=true
            this.$axios
                .get(`${this.$root.apiBaseUrl}/admin/getAllUser`,  {
                    headers:{sessionId:localStorage.getItem("sessionId")}
                })
                .then((response) => {
                    this.users = response.data;
                    this.dataLoading=false
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        deleteUser(id){
            this.adminToDelete = id  // assegno per il popup di conferma che richiama questa funzione
            if(!this.deleteUserConfirmation){
                this.deleteUserConfirmation = true
            }else{            
                this.$axios
                .post(`${this.$root.apiBaseUrl}/admin/removeUser`, {id:id,sessionId:localStorage.getItem("sessionId")})
                .then(() => {
                    this.updateData()
                })
                .catch((error) => {
                    console.log(error);

                })
                this.deleteUserConfirmation = false
                this.adminToDelete = undefined
            }
        },
        modifyAdminFunction(adminToModify){
            this.modifyAdmin = true
            setTimeout(()=> { this.$refs.modifyAdminRef.setAdmin(adminToModify)}, 50)
        },
        esportUsersCSV(){
            this.csvLoading = true
            fetch(`${this.$root.apiBaseUrl}/admin/exportUsers/csv`, {
                    headers:{sessionId:localStorage.getItem("sessionId")}
                }) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                this.csvLoading = false
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
                console.log(err);
            });
        }
    }
};
</script>

<style></style>