<template>
    <resetPassword v-if="resetPassword" @resetDone="resetPassword=false" />
    <div v-if="!resetPassword">
        <div class="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Accedi
                </h2>
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div class="bg-white shadow rounded-lg ">
                    
                    <div class="w-full h-10 flex justify-between">
                        <div class="bg-gray-processed w-1/2 flex items-center justify-between">
                        <img src="../assets/logo-en.png" class="h-6 m-2" >
                        <div class="logo-red-text font-bold text-xl ">
                            REF
                        </div>
                        </div>
                        <div class="bg-gray-processed w-1/2 flex items-center justify-between">
                        <div class="logo-green-text font-bold text-xl ">
                            ILL
                        </div>
                        <img src="../assets/LOGO-CLICK-AND-TWIST-2.png" class="h-6 m-2" >
                        </div>
                    </div>
                    <div class="py-8 px-14">

                    
                    <form class="space-y-6" @submit.prevent="login">
                        <div>
                            <label for="email" class="block text-sm font-medium text-gray-700">
                                Email
                            </label>
                            <div class="mt-1">
                                <input id="email" name="email" type="email" autocomplete="email" required
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    v-model="email" />
                            </div>
                        </div>

                        <div>
                            <label for="password" class="block text-sm font-medium text-gray-700">
                                Password
                            </label>
                            <div class="mt-1">
                                <input id="password" name="password" type="password" autocomplete="current-password" required
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    v-model="password" />
                            </div>
                        </div>

                        <div v-if="loginError">
                            <div 
                                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400">
                                Credenziali errate
                            </div>
                        </div>

                        <div>
                            <button type="submit"
                                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Accedi
                            </button>
                        </div>
                    </form>
                    <div>
                        <button @click="resetPassword=true"
                            class="flex justify-center text-xs font-medium text-indigo-700 my-2">
                            Ripristina password
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
const sha256 = require('js-sha256');
import resetPassword from './resetPassword.vue'


export default {
    name: "loginComponent",
    data() {
        return {
            email: '',
            password: '',
            loginError : false,
            resetPassword:false,
        }
    },
    components:{
        resetPassword
    },
    methods: {
        login() {
            let passwordHash = sha256(this.password);
            this.password = "";
            this.$axios
                .post(`${this.$root.apiBaseUrl}/adminAutentication`, {email:this.email, passwordHash:passwordHash})
                .then((response) => {
                    localStorage.setItem("sessionId",response.data.id)
                    this.$emit("loginSuccess")
                })
                .catch((error) => {
                    if(error.response.status === 401){
                        this.loginError = true
                    }
                    console.log(error);
                })
            // Implementare il login qui
        },
        isResetPassword(){
            this.resetPassword = true
        }
    }
}
</script>