import { createApp } from 'vue'
import "vue-time-date-range-picker/dist/vdprDatePicker.min.css"
import App from './App.vue'
import './style.css'
import axios from 'axios'
import VueGoogleMaps from '@fawmi/vue-google-maps'



let app = createApp(App)
app.config.globalProperties.$axios = axios;
app.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBgLYnMiKIlH5RjxmfKD-NhcIgUX-VjCsw',
      libraries: "places"
    }
  })
app.mount('#app')
