<template>
  <div class="flex h-screen">
    <!-- Sidebar -->
    <div class="w-56 bg-gray-200 flex flex-col fixed  h-screen">
      <!--
      <h1 class=" font-bold mx-4 mt-2 text-orange-500 text-3xl text-center">REFILL</h1>
      -->
      <div class="w-full h-10 flex justify-between">
        <div class=" w-1/2 flex items-center justify-between  bg-gray-processed"> <!-- bg-gray-processed -->
          <img src="../assets/logo-en.png" class="h-6 m-2" >
          <div class="logo-red-text font-bold text-xl ">
            REF
          </div>
        </div>
        <div class="w-1/2 flex items-center justify-between bg-gray-processed">
          <div class="logo-green-text font-bold text-xl ">
            ILL
          </div>
          <img src="../assets/LOGO-CLICK-AND-TWIST-2.png" class="h-6 m-2" >
        </div>
      </div>

      <h1 class=" font-bold px-4 pb-2 text-xl text-center">Admin page</h1>
      <div class="border border-gray-400 mx-2"></div>

      <button v-if="$root.adminInfo.adminlevel<=1" :class="{ 'logo-green-bg text-white': selectedTab === 'accessStatistics', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'accessStatistics' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1	flex col-2 text-base " @click="selectedTab = 'accessStatistics'">
        <svg xmlns="http://www.w3.org/2000/svg" class="mx-2" height="20" :class= "{'fill-white':selectedTab === 'accessStatistics', 'fill-gray-700':selectedTab != 'accessStatistics' }" viewBox="0 96 960 960" width="20"><path d="m89 807-49-36 188-300 119.571 140L507 353l109 162q-16 2-31 6.5T555 532l-45-69-152.557 249L236 571 89 807Zm788 209L743 882q-21 15-45.5 23t-50.5 8q-70.833 0-120.417-49.618Q477 813.765 477 742.882 477 672 526.618 622.5q49.617-49.5 120.5-49.5Q718 573 767.5 622.583 817 672.167 817 743q0 26-8.5 50.5T786 840.448L920 973l-43 43ZM646.859 853Q693 853 725 821.141q32-31.859 32-78T725.141 665q-31.859-32-78-32T569 664.859q-32 31.859-32 78T568.859 821q31.859 32 78 32ZM721 525q-15-6-31-8t-33-4l214-337 49 36-199 313Z"/></svg>
        Statistiche Accessi
      </button>
      <button :class="{ 'logo-green-bg text-white': selectedTab === 'refillViewStats', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'refillViewStats' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1	flex col-2 text-base " @click="selectedTab = 'refillViewStats'">
        <svg xmlns="http://www.w3.org/2000/svg" class="mx-2" height="20" :class= "{'fill-white':selectedTab === 'refillViewStats', 'fill-gray-700':selectedTab != 'refillViewStats' }" viewBox="0 96 960 960" width="20"><path d="m89 807-49-36 188-300 119.571 140L507 353l109 162q-16 2-31 6.5T555 532l-45-69-152.557 249L236 571 89 807Zm788 209L743 882q-21 15-45.5 23t-50.5 8q-70.833 0-120.417-49.618Q477 813.765 477 742.882 477 672 526.618 622.5q49.617-49.5 120.5-49.5Q718 573 767.5 622.583 817 672.167 817 743q0 26-8.5 50.5T786 840.448L920 973l-43 43ZM646.859 853Q693 853 725 821.141q32-31.859 32-78T725.141 665q-31.859-32-78-32T569 664.859q-32 31.859-32 78T568.859 821q31.859 32 78 32ZM721 525q-15-6-31-8t-33-4l214-337 49 36-199 313Z"/></svg>
        Statistiche Refill
      </button>
      <div class="border border-gray-300 mx-2"></div>

      <button :class="{ 'logo-green-bg text-white': selectedTab === 'manageRefill', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'manageRefill' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1 flex col-2 text-base" @click="selectedTab = 'manageRefill'">
        <svg xmlns="http://www.w3.org/2000/svg" height="20" :class= "{'fill-white':selectedTab === 'manageRefill', 'fill-gray-700':selectedTab != 'manageRefill'}" class="mx-2"  viewBox="0 96 960 960" width="20"><path d="M522 974v-60q45-6 87.5-23.5T689 844l41 44q-46 38-98 59t-110 27Zm269-148-43-41q27-36 45-77.5t25-89.5h61q-8 60-30.5 112.5T791 826Zm27-292q-7-48-25-89t-45-78l43-41q38 50 58 99t30 109h-61ZM437 974q-152-17-254-130.5T81 576q0-154 102-267.5T437 178v60q-127 17-211.5 113.5T141 576q0 128 84.5 224.5T437 914v60Zm253-666q-39-27-81.5-44.5T524 238v-60q54 8 107 29t99 57l-40 44ZM480 787q-85-72-126-133.5T313 540q0-79 50.5-125.5T480 368q66 0 116.5 46.5T647 540q0 52-41 113.5T480 787Zm0-209q19 0 32-13t13-32q0-17-13-31t-32-14q-19 0-32 14t-13 31q0 19 13 32t32 13Z"/></svg>
        Gestisci punti refill
      </button>

      <div v-if="$root.adminInfo.adminlevel<=1" class="border border-gray-300 mx-2"></div>
      <button  v-if="$root.adminInfo.adminlevel<=1" :class="{ 'logo-green-bg text-white': selectedTab === 'manageNews', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'manageNews' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1	flex col-2 text-base" @click="selectedTab = 'manageNews'">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" :class= "{'fill-white':selectedTab === 'manageNews', 'fill-gray-700':selectedTab != 'manageNews'}" class="mx-2" height="20" viewBox="0 0 24 24" width="20" fill="#000000"><path d="M22,3l-1.67,1.67L18.67,3L17,4.67L15.33,3l-1.66,1.67L12,3l-1.67,1.67L8.67,3L7,4.67L5.33,3L3.67,4.67L2,3v16 c0,1.1,0.9,2,2,2l16,0c1.1,0,2-0.9,2-2V3z M11,19H4v-6h7V19z M20,19h-7v-2h7V19z M20,15h-7v-2h7V15z M20,11H4V8h16V11z"/></svg>
        Gestisci news
      </button>

      <div v-if="$root.adminInfo.adminlevel<=1" class="border border-gray-300 mx-2"></div>
      <button v-if="$root.adminInfo.adminlevel<=1" :class="{ 'logo-green-bg text-white': selectedTab === 'users', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'users' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1	flex col-2 text-base" @click="selectedTab = 'users'">
        <svg xmlns="http://www.w3.org/2000/svg" :class= "{'fill-white':selectedTab === 'users', 'fill-gray-700':selectedTab != 'users'}" class="mx-2" height="20" viewBox="0 96 960 960" width="20"><path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z"/></svg>
        Utenti
      </button>

      <div v-if="$root.adminInfo.adminlevel==1" class="border border-gray-300 mx-2"></div>
      <button v-if="$root.adminInfo.adminlevel==0" :class="{ 'logo-green-bg text-white': selectedTab === 'manageAdmin', 'bg-transparent border-gray-400 hover:bg-gray-300 text-gray-700': selectedTab !== 'manageAdmin' }" class="btn-sidebar text-left mx-2 my-1 rounded-lg p-1	flex col-2 text-base" @click="selectedTab = 'manageAdmin'">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20" :class= "{'fill-white':selectedTab === 'manageAdmin', 'fill-gray-700':selectedTab != 'manageAdmin'}" class="mx-2"><g><circle cx="17" cy="15.5" fill-rule="evenodd" r="1.12"/><path d="M17,17.5c-0.73,0-2.19,0.36-2.24,1.08c0.5,0.71,1.32,1.17,2.24,1.17 s1.74-0.46,2.24-1.17C19.19,17.86,17.73,17.5,17,17.5z" fill-rule="evenodd"/><path d="M18,11.09V6.27L10.5,3L3,6.27v4.91c0,4.54,3.2,8.79,7.5,9.82 c0.55-0.13,1.08-0.32,1.6-0.55C13.18,21.99,14.97,23,17,23c3.31,0,6-2.69,6-6C23,14.03,20.84,11.57,18,11.09z M11,17 c0,0.56,0.08,1.11,0.23,1.62c-0.24,0.11-0.48,0.22-0.73,0.3c-3.17-1-5.5-4.24-5.5-7.74v-3.6l5.5-2.4l5.5,2.4v3.51 C13.16,11.57,11,14.03,11,17z M17,21c-2.21,0-4-1.79-4-4c0-2.21,1.79-4,4-4s4,1.79,4,4C21,19.21,19.21,21,17,21z" fill-rule="evenodd"/></g></svg>
        Gestisci admin
      </button>

      <div class="items-center justify-between p-4 bg-white shadow-lg rounded-md w-52 m-2 fixed bottom-0 ">
        <span v-if="$root.adminInfo.adminlevel==0" class="px-2 text-sm text-gray-500 rounded-md absolute top-0 right-0 m-2 mb-6 bg-purple-200" > admin </span>
        <span v-if="$root.adminInfo.adminlevel==1" class="px-2 text-sm text-gray-500 rounded-md absolute top-0 right-0 m-2 mb-6 bg-green-200" > editor </span>
        <span v-if="$root.adminInfo.adminlevel==2" class="px-2 text-sm text-gray-500 rounded-md absolute top-0 right-0 m-2 mb-6 bg-orange-200" > distributor </span>

        <button @click="resetPassword()"
                            class="flex justify-center text-xs font-medium text-indigo-700 m-3 absolute top-0 left-0">
                            Cambia password
                        </button>

        <div class="flex items-center mt-4">
          <div class="mb-2">
            <div class="flex items-center justify-between ">
              <div class="ml-auto">
                <div class=" font-medium text-lg text-gray-800 ml-auto break-words w-48"> {{$root.adminInfo.username}} </div>
                <div class="text-gray-500 text-xs w-48 break-words w-48"> {{$root.adminInfo.email}} </div> <!--text-ellipsis overflow-hidden-->
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <button class=" w-full px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="$emit('logout')">Logout</button>
        </div>
      </div>
      
  
    </div>

    <!-- Main content -->
    <div class="w-4/5 p-8 ml-56">
      <h2 class="text-3xl font-bold mb-4">{{ getTitle(selectedTab) }}</h2>

      <!-- Content for selected tab -->
      <div v-if="selectedTab === 'accessStatistics'">
        <accessStatistics/>
        <!-- Insert content for statistics tab -->
      </div>
      <div v-if="selectedTab === 'refillViewStats'">
        <refillPointWiewsStatistics/>
        <!-- Insert content for statistics tab -->
      </div>
      <div v-if="selectedTab === 'manageRefill'">
        <!-- Insert content for refill tab -->
        <manageRefillPoint/>
      </div>
      <div v-if="selectedTab === 'manageNews'">
        <!-- Insert content for news tab -->
        <manageNews/>
      </div>
      <div v-if="selectedTab === 'users'">
        <!-- Insert content for users tab -->
        <manageUsers/>
      </div>
      <div v-if="selectedTab === 'manageAdmin'">
        <manageAdmin/>
        <!-- Insert content for users tab -->
      </div>

    </div>
  </div>
</template>

<script>
import accessStatistics from './statistics/accessStatistics.vue'
import refillPointWiewsStatistics from './statistics/refillPointWiewsStatistics.vue'
import manageRefillPoint from './manageRefillPoint/manageRefillPoint.vue'
import manageNews from './manageNews/manageNews.vue'
import manageAdmin from './manageAdmin/manageAdmin.vue'
import manageUsers from './manageUsers/manageUsers.vue'


export default {
  name: 'mainPage',
  components:{
    accessStatistics,
    refillPointWiewsStatistics,
    manageRefillPoint,
    manageNews,
    manageAdmin,
    manageUsers
  },
  data() {
    return {
      selectedTab: ''
    }
  },
  mounted(){
    this.selectedTab = this.$root.adminInfo.adminlevel <=1? 'accessStatistics': 'refillViewStats'
  },
  methods:{
    getTitle(selectedTab){
      switch(selectedTab){
        case 'accessStatistics': return 'Statistiche accessi';
        case 'refillViewStats': return 'Statistiche refill';
        case 'manageRefill': return 'Gestione punti refill';
        case 'manageNews': return 'Gestione news';
        case 'users': return 'Gestione utenti';
        case 'manageAdmin': return 'Gestione admin'
      }
    },
    resetPassword(){
      this.$emit('resetPassword');
    }
  }
}
</script>

<style>

</style>
