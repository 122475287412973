<template>
  <mainPage v-if="isLogged && adminInfo.username!=''" @logout="logout()" @resetPassword="resetPassword()"/>
  <loginComponent ref="loginComp" v-if="!isLogged" @loginSuccess="getAdminAccountInfo(), isLogged = true" />
</template>

<script>
import mainPage from './components/mainPage.vue'
import loginComponent from './components/loginPage.vue'

export default {
  name: 'App',
  components: {
    mainPage,
    loginComponent
  },
  mounted() {
    if (localStorage.getItem("sessionId") === undefined  || localStorage.getItem("sessionId") === null ) {
      this.isLogged = false
    } else {
      this.isLogged = true
      this.getAdminAccountInfo()
    }
  },
  data() {
    return {
      apiBaseUrl: 'https://api.glassrefill.com',
      isLogged: false,
      adminInfo:{
        username:"",
        adminlevel:"",
        email:""
      }
    }
  },
  methods: {
    getAdminAccountInfo() {
      this.$axios
        .post(`${this.apiBaseUrl}/getAdminInfo/session`, { sessionId: localStorage.getItem("sessionId") })
        .then((response) => {
          this.adminInfo={
            username:response.data.username,
            adminlevel:response.data.adminlevel,
            email:response.data.email
          }
        })
        .catch((error) => {
          if(error.response.status === 401){
            this.isLogged = false
          }
          console.log(error);
        })
    },
    logout(){
      this.isLogged = false
      localStorage.removeItem("sessionId")
    },
    resetPassword(){
      this.logout();
      setTimeout(()=>{
        this.$refs.loginComp.isResetPassword()
      },30)
    }
  }
}
</script>

<style></style>
