<template>
  <div>
    <div ref="map" class="z-0"></div>
  </div>
</template>
  
<script>
import L from 'leaflet';

export default {
  name: 'mapEngine',
  data() {
    return {
      mapCenter: null,
      map: null,
      markersInMap: [],
      markers: [],
      latLng: null,
      newMarker: { lat: undefined, lng: undefined }
    };
  },
  mounted() {
    this.setMapHeight();
    this.initMap();
    this.map.setView([45.434407, 12.338697])
  },
  methods: {
    initMap() {  // inizializzo la mappa
      // Crea la mappa
      this.map = L.map(this.$refs.map, { attributionControl: false }).setView([45.434405, 12.338698], 11);  //lascio o rimuovo gli attributi? per irmuoverli: { attributionControl:false }

      // Aggiunge la mappa di OpenStreetMap come layer
      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ',
        maxZoom: 18,
      }).addTo(this.map);

      this.map.on('moveend', () => {
        var mapBound = {
          "north": this.map.getBounds().getNorth(),
          "south": this.map.getBounds().getSouth(),
          "east": this.map.getBounds().getEast(),
          "west": this.map.getBounds().getWest(),
        }
        //var center = this.map.getCenter();
        //console.log('Nuove coordinate:', center.lng.toFixed(4), center.lat.toFixed(4), "Zoom:", this.map._zoom);
        this.$axios
          .get(`${this.$root.apiBaseUrl}/getRefillPoint?north=${mapBound.north}&south=${mapBound.south}&east=${mapBound.east}&west=${mapBound.west}`)
          .then((response) => {
            this.markers = response.data
            this.updateMarker(response.data)
          })
          .catch((error) => {
            console.log(error);

          })
      });
    },
    updateMarker(allMarker) {// aggiorna i marker dei posti 
      this.map.eachLayer((layer) => {  //rimuovo tutti i marker
        if (layer instanceof L.Marker) {
          this.map.removeLayer(layer);
        }
      });
      allMarker.forEach((marker) => {  //aggiungo i marker
        if (marker.lat != this.newMarker.lat && marker.lng != this.newMarker.lng) {
          L.marker([marker.lat, marker.lng], {
            icon: L.icon({
              iconUrl: 'https://i.imgur.com/Zn8Mf5F.png',
              iconSize: [30, 30],
            })
          }).addTo(this.map);
        }
      });
      L.marker([this.newMarker.lat, this.newMarker.lng], {  //aggiungo il marker da vedere
      }).addTo(this.map);
    },
    addMarker(lat, lng) {
      this.newMarker = { lat: lat, lng: lng }
      this.updateMarker(this.markers)
      this.map.setView([lat, lng], 16)
    },
    setMapHeight() {
      this.$refs.map.style = `height: ${window.innerHeight * (7 / 12)}px;`  //7/12
    },
  }
};
</script>
  
<style>
@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
</style>