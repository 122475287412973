<template>
    <div class="">
        <div class="w-full p-4">
            <div ref="chart" class="h-72"></div>
        </div>
        <div class="w-full p-4">
            <div class="flex">
                <div class="container mx-auto p-4">
                    <div class="flex justify-center">
                        <div class="w-full">
                            <label class="block text-gray-700 text-sm font-bold mb-2">Filtra per nome, indirizzo, citta:</label>
                            <input type="text" name="luogo" v-model="researchLuogo" id="email"
                                class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-2 sm:text-sm border-gray-300 rounded-md border p-2"
                                placeholder="luogo">
                        </div>
                    </div>
                </div>
                <div class="container mx-auto p-4">
                    <div class="flex justify-center">
                        <div class="">
                            <label class="block text-gray-700 text-sm font-bold mb-2 w-52">Seleziona un intervallo di
                                date:</label>
                            <vue-flatpickr class="form-input rounded-md shadow-sm mt-1 block w-full"
                                v-model:modelValue="moduleDateRange" :config="flatpickrOptions"></vue-flatpickr>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end m-4">
                    <button class="px-4 py-2 rounded-md bg-blue-500 hover:bg-blue-700 text-white" @click="updateAnaliticsData">
                        Cerca
                    </button>
                </div>
            </div>
            <table class="w-full border-collapse">
                <thead>
                    <tr class="bg-gray-200">
                        <th class="px-4 py-2 text-left">Nome</th>
                        <th class="px-4 py-2 text-left">Indirizzo</th>
                        <th class="px-4 py-2 text-left">
                            <button class="px-px py-px rounded-md  text-black p-2" @click="toggleSortDirection()">
                                {{ sortDirection === 'asc' ? '▼' : '▲' }}
                            </button>
                            Visualizzazioni
                        </th>
                        <th class=" text-center w-8"></th>
                    </tr>
                </thead>
                <tbody class="">
                    <tr v-for="(row) in sortedData" :key="row" >
                        <td class="border px-4 py-2">{{ row.name }}</td>
                        <td class="border px-4 py-2">{{ row.address }}</td>
                        <td class="border px-4 py-2">{{ row.views }}</td>
                        <input type="checkbox" class="justify-self-center my-4 w-full"
                            v-if="isSelected(row.id_point)" checked
                            @change="changeSelection(row.id_point, $event.target.value)" />
                        <input type="checkbox" class="justify-self-center my-4 w-full"
                            v-if="!isSelected(row.id_point)"
                            @change="changeSelection(row.id_point, $event.target.value)" />
                    </tr>
                </tbody>
            </table>
            <div v-if="dataLoading" class="w-full my-2 items-center flex justify-center align-center">
                <svg role="status" class="justify-self-center text-gray-200 animate-spin fill-blue-400 h-10 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-2b2c4366=""><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" data-v-2b2c4366=""></path><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" data-v-2b2c4366=""></path></svg>
            </div>
        </div>
    </div>
</template>
  
<script>
import * as echarts from 'echarts';
import VueFlatpickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment'
import _ from 'lodash';


//TODO: invertire checkbox quando si cambia ordine della lista

export default {
    name: 'refillPointWiewsStatistics',
    components: {
        VueFlatpickr,
    },
    data() {
        return {
            sortDirection: 'asc',
            dateRange: { startDate: moment().add(-1, 'days').format('DD/MM/YYYY'), endDate: moment().format('DD/MM/YYYY') },
            flatpickrOptions: {
                mode: 'range',
                altInput: true,
                altFormat: 'd/m/Y',
                dateFormat: 'd/m/Y',
                maxDate: "today",
                defaultDate: [moment().add(-1, 'days').format('DD/MM/YYYY'), 'today'],
            },
            data: [],
            selected: [],
            researchLuogo: '',
            chartData: {},  //sample: {[id_point]:{[data:number]}}
            chartOptions: {
                tooltip: {
                    trigger: 'axis'
                },
                // Opzioni del grafico ECharts
                title: {
                    text: 'Accessi nel tempo',
                },
                xAxis: {
                    data: [],
                },
                yAxis: {},
                series: [
                    {
                        name: 'Accessi',
                        type: 'line',
                        data: [],
                    },
                ],
            },
            dataLoading:false
        };
    },
    computed: {
        sortedData() {
            return this.data.slice().sort((a, b) => {
                if (this.sortDirection === 'asc') {
                    return a.views - b.views;
                } else {
                    return b.views - a.views;
                }
            });
        },
    },
    mounted() {
        // Inizializza il grafico ECharts
        this.chart = echarts.init(this.$refs.chart);
        window.addEventListener('resize', () => { this.chart.resize(); });
        this.chart.setOption(this.chartOptions);
        this.updateAnaliticsData()
    },
    methods: {
        setDataGroupBy(selected){
            this.groupBy = selected;
        },
        toggleSortDirection() {
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        },
        dateRangeIsChanged() {  // TODO_OKAY: da fare solo qaundo faccio cerca perché crea inconsistenza dei dati sul grafico , ora é ognio volta che cambio data,
            if (typeof this.moduleDateRange === 'string' && this.moduleDateRange.split(" to ").length > 1) {  //moduleDateRange se é stringa allora le date nel form sono state selezionate entrambe, quindi posso aggiornarle 
                this.dateRange = { startDate: this.moduleDateRange.split(" to ")[0], endDate: this.moduleDateRange.split(" to ")[1] }
            }else if((typeof this.moduleDateRange === 'string' && this.moduleDateRange.split(" to ").length === 1)){
                this.dateRange = { startDate: this.moduleDateRange, endDate: this.moduleDateRange }
            }
        },
        changeSelection(id_point) {
            if (!_.has(this.chartData, [id_point].toString())) {
                this.chartData[[id_point].toString()] = {}
                this.getChartData(id_point);
            } else {
                delete this.chartData[[id_point]] //elimino
                this.updateChart() //aggiorno il grafico
            }
        },
        isSelected(id_point) {
            return _.has(this.selected, [id_point].toString())
        },
        updateAnaliticsData() {
            this.dateRangeIsChanged()
            let urlDir = this.researchLuogo === ''?'analytics/getRefillPointViewed/BetweenDate':'analytics/researchRefillPoint/BetweenDate'
            this.dataLoading=true;
            this.$axios
                .get(`${this.$root.apiBaseUrl}/${urlDir}?start=${this.dateRange.startDate}&stop=${moment(this.dateRange.endDate, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY') /* comprendo il giorno di fine */}&searchText=${this.researchLuogo}`)
                .then((response) => {
                    this.dataLoading = false
                    this.data = response.data;
                    this.clearChart();
                })
                .catch((error) => {
                    console.log(error);

                })
        },
        getChartData(id_point) {
            this.$axios
                .get(`${this.$root.apiBaseUrl}/analytics/getRefillPointViewed/ByDay/fromID/BetweenDate?start=${this.dateRange.startDate}&stop=${moment(this.dateRange.endDate, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY') /* comprendo il giorno di fine */}&id=${id_point}`)
                .then((response) => {
                    this.chartData[[id_point]] = response.data
                    this.updateChart()
                })
                .catch((error) => {
                    console.log(error);

                })
        },
        clearChart() {
            this.chartData = {}  // inizializzo le selezioni
            this.updateChart()   // inizializzo il grafico
        },
        updateChart() {
            let getSeries = (date) => {
                let series = []
                for (const id_point in this.chartData) {//per ogni cittá
                    let seriesData = []
                    for (let i = 0; i < date.length; i++) {
                        seriesData.push("0");//metto a priori zero poi se lo trovo lo sostituisco a quell'indice
                        for (let index = 0; index < this.chartData[id_point].length; index++) {  // per ogni data guardo se corrisponde a quella che sto cercando
                            if (this.chartData[id_point][index].date === date[i]) {  // se la data c'é la aggiungo altrimenti aggiungo 0
                                seriesData[i] = this.chartData[id_point][index].views;  // sostituisco lo 0
                            }
                        }
                    }
                    series.push({
                        name: this.chartData[id_point][0].name,
                        type: 'line',
                        data: seriesData,
                    })
                }
                return series
            }

            let getDate = () => {  // genero tuute le date dell'intervallo
                let date = []
                if (Object.keys(this.chartData).length === 0) { return date } //se non ho nessun paese o cittá selezionato allora rimuovo le date
                let currentDate = moment(this.dateRange.startDate, 'DD/MM/YYYY');
                while (currentDate <= moment(this.dateRange.endDate, 'DD/MM/YYYY')) {
                    // Format the current date as a string in the format "DD/MM/YYYY"
                    let dateString = currentDate.format('DD/MM/YYYY');
                    date.push(dateString)
                    currentDate.add(1, 'days');
                }
                return date
            }

            let date = getDate()
            let series = getSeries(date)

            this.chartOptions = {
                tooltip: {
                    trigger: 'axis'
                },
                title: {
                    text: 'Accessi nel tempo',
                },
                xAxis: {
                    data: date,
                },
                yAxis: {},
                //series sono gli accessi di un paese nel tempo
                series: series
            }
            this.chart.setOption(this.chartOptions, true);  // con true nel secondo parametro viene eseguito un REPLACE_MERGE 
        }
    }
};
</script>

<style></style>